import { useState } from 'react';
import { useTracking } from 'react-tracking';
import loadable from '@loadable/component';

import { Grid, Heading, Button, Text } from '@simplywallst/ui-core';

import { getStaticUrl, useCloudflareImage } from '@/hooks/useCloudflareImage';

import { BannerWrapper, ImageWrapper } from './styled';

const MigrateLegacyPortfolioModal = loadable(
  () => import('./MigrateLegacyPortfolioModal'),
  {
    resolveComponent: (c) => c.MigrateLegacyPortfolioModal,
  }
);

interface Props {
  v1PortfolioId: number;
}

export const MigrateLegacyPortfolioBanner = ({ v1PortfolioId }: Props) => {
  const image = useCloudflareImage({
    src: getStaticUrl('portfolio/feature-usp/portfolio-copy.png'),
    width: 308,
    height: 258,
  });

  const { Track, trackEvent } = useTracking({
    type: 'track',
    modifier: 'MigrateLegacyPortfolioBanner',
  });

  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    trackEvent({ action: 'openModal', v1PortfolioId });
    setShowModal(true);
  };

  const handleCancel = () => {
    trackEvent({ action: 'closeModal', v1PortfolioId });
    setShowModal(false);
  };

  return (
    <Track>
      <BannerWrapper
        data-cy-id="portfolio-new-banner"
        gapMd="6px"
        templateColumnsMd="80% auto"
      >
        <Grid maxWidth="600px" rowGap="12px" justifyItems="start">
          <Heading as="h3" typography="baseStrong">
            Try out the new portfolio experience with exciting new features
            coming
          </Heading>
          <Text typography="xSmall" color="whiteRGBA" opacity={0.7}>
            Copy your holding details to a new portfolio and enjoy our new
            portfolio experience while we keep developing it. You can still
            access this portfolio as long as you like.
          </Text>
          <Button styleType="louderLarge" onClick={handleOpen}>
            Copy to new portfolio
          </Button>
        </Grid>
        <ImageWrapper alignContent="center" justifyContent="center">
          <img alt="Track portfolio's progress" src={image} />
        </ImageWrapper>

        {showModal && (
          <MigrateLegacyPortfolioModal
            v1PortfolioId={v1PortfolioId}
            onClose={handleCancel}
          />
        )}
      </BannerWrapper>
    </Track>
  );
};
